'use client'

import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Image,
  useColorMode,
} from '@chakra-ui/react'
import voilion from "../../assets/Img-voilion.png"
import ButtonCuts from '../Button/ButtonCuts';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

import { useEffect, useState } from 'react';
import { useModalVisibility } from '../../context/ModalToast/useModalToast';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase/firebase';
import useAuthStore from '../../store/authStore';
import { useNavigate } from 'react-router-dom';
import { storage } from '../../firebase/firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import SkeletonBody from '../../Layout/Skeleton';
import utilityStore from '../../store/utilStore';
AOS.init({
  duration: 700,
})

export default function Home() {
  const redirect = useNavigate()
  const modal = useModalVisibility()
  const [image , setImage] = useState('')
  const setSkeleton = utilityStore((state) => state.setSkeleton);
  const [authUser] = useAuthState(auth);
  const [loader , setLoader] = useState(false)

  const handleSignUpModal = ()=>{
     modal.onSignupOpen()
  }

  // ToDO
  // const storageKey = 'cachedImageURL';

  // useEffect(() => {
  //   const fetchImageUrl = async () => {
      
  //     try {
  //       const cachedUrl = localStorage.getItem(storageKey);
  //       if (cachedUrl) {
  //         setImage(cachedUrl);
  //       } else {
  //         setSkeleton(true)
  //         setLoader(true)
  //         const imageRef = ref(storage, 'assets/Img-voilion.png');
  //         getDownloadURL(imageRef).then((url) => {
  //           setLoader(false)
  //           setImage(url);
  //           setSkeleton(false)
  //           localStorage.setItem(storageKey, url);
  //         }).catch((error) => {
  //           console.error(error);
  //           setLoader(false);
  //           setSkeleton(false)
  //         })          
  //       }
  //     } catch (error) {
  //       console.error('Error fetching image URL:', error);
  //     }
  //   };
  //   fetchImageUrl();
  // }, []);


  //   if(loader){
  //     return (
  //       <SkeletonBody/>
  //     )
  //   }
  const {colorMode} = useColorMode()

  return (
    <Container maxWidth={'1700px'} height={{base:"auto",md:"90vh"}}>
      <Stack height={"100%"}
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        direction={{ base: 'column', md: 'row' }}>
        <Stack flex={1} spacing={{ base: 5, md: 10 }} height={"100%"} marginTop={{base:'50px',md:'0'}} justifyContent={{base:'start',md:'center'}}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '50px' }}>
            <Text
            color="var(--highlighted-color)"
              as={'span'}
              position={'relative'}
              fontStyle={'italic'}
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: 'red.400',
                zIndex: -1,
              }}>
                Empower Brand Promotions with,
            </Text>
            <br />
            <Text as={'span'} color={colorMode=='light' ? 'black' :'white'}  whiteSpace={{ base: 'normal', sm: 'normal', lg: 'nowrap' }} fontStyle={'italic'}>
            Vibrant Music and Stunning Animation!
            </Text>
          </Heading>
          <Text color={'var(--primary-text-color)'} fontSize={{base:'16px',sm:'16px',md:'16px'}} textAlign={{ base: 'center', sm: 'start' }}>
          Unleash the Power of Your Brand's Narrative through Compelling Music and Dynamic Animation.
          Experience the Next Level of Marketing Excellence with 'The Promo Catalyst' - Where Creativity Fuels Impact and Every Frame Paints Your Success Story in Vibrant Colors.
          </Text>
          <Stack spacing={{ base: 4, sm: 6 }} justifyContent={'center'} direction={{ base: 'column', sm: 'row' }}>
            {authUser ? <ButtonCuts text={'Hire Catalyst'} onClick={()=>redirect("/dashboard/explore")} variant={'solid'}></ButtonCuts> : <ButtonCuts text={'Get Started'} onClick={handleSignUpModal}variant={'solid'}></ButtonCuts>}
            <ButtonCuts text={'How It Works'} onClick={()=>redirect("/about")} variant={'outline'}></ButtonCuts>
          </Stack>
        </Stack>
        <Flex       
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}
          height={"100%"}>
          <Box
            position={'relative'}
            rounded={'2xl'}
            height={"100%"}
            width={'full'}
            overflow={'hidden'}>
            <Image
              alt={'Hero Image'}
              fit={'contain'}
              align={'center'}
              w={'100%'}
              h={'100%'}
              src={voilion}
            />
            

          </Box>
        </Flex>
      </Stack>
    </Container>
  )
}
