import { Box, Flex, Image, Spinner, Text, useColorMode } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebase";
import loader from "../assets/loaderMain.gif"
import lightLoader from "../assets/loaderLight.gif";
import { infinity } from 'ldrs';
infinity.register()

// instead of adding the Sidebar component to every page, we can add it only once to the PageLayout component and wrap the children with it. This way, we can have a sidebar on every page except the AuthPage.

const PageLayout = ({ children }) => {
	const [user, loading] = useAuthState(auth);
    const [signOut, isLoggingOut, error] = useSignOut(auth);
	

	const checkingUserIsAuth = !user && loading;
	if (checkingUserIsAuth) return <PageLayoutSpinner text={"Loggin You In..."} />;

	return (
		<Flex>
			<Box flex={1} w={{ base: "calc(100% - 70px)", md: "calc(100% - 240px)" }} mx={"auto"}>
				{children}
			</Box>
		</Flex>
	);
};

export default PageLayout;

export const PageLayoutSpinner = ({text}) => {
	const {colorMode} = useColorMode()
	return (
		<Flex flexDir='column' h='100vh' w="95vw" bg="var(--primary-bg-color)" alignItems='center' justifyContent='center'>
			{/* <Spinner size='xl' color="green"/> */}
			{/* {colorMode == 'dark' ? <Image src={loader} /> : <Image src={lightLoader} />}
			 */}
			 <l-infinity
				size="95"
				stroke="4"
				stroke-length="0.15"
				bg-opacity="0.1"
				speed="1.3" 
				color="var(--highlighted-color)" 
			></l-infinity>
            
            <Text mt={3} color={colorMode == 'dark' ? 'white' : 'black'}>{text}</Text>
		</Flex>
	);
};