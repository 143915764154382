import { Button, useColorMode } from '@chakra-ui/react'
import React from 'react'
import btn from "./Button.module.css"

const ButtonCuts = ({text,variant,bRadius,onClick,isLoading}) => {
  const {coloMode} = useColorMode()
  const handleClick = () => {
    if (onClick) {
      onClick(); 
    }
  };
  return (
    <>
        <Button borderRadius={bRadius} style={{color:coloMode=='dark' ? 'white' : 'black'}} isLoading={isLoading} className={variant == 'outline' ? btn.btn :btn.btnFilled} onClick={handleClick} variant={variant}>{text}</Button>
        {/* <Button colorScheme='green' onClick={handleClick}  variant={variant}>{text}</Button> */}
    </>
  )
}

export default ButtonCuts
