import { Container, Text } from '@chakra-ui/react'
import React from 'react'
import UploadAudio from '../../Components/Audio/UploadAudioFile'
import AudioWaveform from '../../Components/Audio/AudioWaveForm'

const DashboardTrending = () => {
  return (
    <Container maxW={'none'} height={"100vh"}
    color={"var(--primary-text-color)"}
    rounded="md"
    boxShadow="var(--box-shadow)"
    >
      {/* <UploadAudio/> */}
      
    </Container>
  )
}

export default DashboardTrending
