"use client";

import {
  Box,
  Center,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Image,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";

import audioasset from "../../assets/testAudio.mp3";
let newAudio = new Audio(audioasset);

const IMAGE =
  "https://thumbs.dreamstime.com/b/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg";

export default function Card({ nameS, artist, age, image }) {
  const [play, setPlay] = useState(true);

  const handleClickOfMusic = () => {
    setPlay(!play);
    if (!play) {
      newAudio.pause();
    } else {
      newAudio.play();
    }
  };

  return (
    <Center py={12}>
      <Box
        userSelect="none"
        role={"group"}
        p={4}
        // maxW={"330px"}
        w={"full"}
        bg="var(--card-color)"
        boxShadow={"2xl"}
        rounded={"lg"}
        pos={"relative"}
        zIndex={1}
      >
        <Box
          rounded={"lg"}
          mt={-12}
          pos={"relative"}
          height={230}
          _after={{
            transition: "all .3s ease",
            content: '""',
            w: "220px",
            h: "210px",
            pos: "absolute",
            top: 9,
            left: 0,
            // backgroundImage: `url(${IMAGE})`,
            background: "var(--highlighted-color)",
            filter: "blur(15px)",
            zIndex: -1,
          }}
          _groupHover={{
            _after: {
              filter: "blur(20px)",
            },
          }}
        >
          <Image
            rounded={"lg"}
            height={240}
            width={230}
            objectFit={"fill"}
            src={image}
            alt="#"
          />
        </Box>
        <Box marginTop={30} display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} gap="16px">
            <Box
              cursor={"pointer"}
              bg="var(--highlighted-color-light)"
              onClick={handleClickOfMusic}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"50px"}
              height={"50px"}
              borderRadius={"50%"}
            >
              {play ? (
                <FaPlay fontSize={"14px"} />
              ) : (
                <FaPause fontSize={"14px"} />
              )}
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Text
                userSelect="none"
                fontSize={"2xl"}
                fontWeight={500}
                lineHeight={"16px"}
                // fontStyle={"italic"}
              >
                <marquee direction="left" scrollamount="4">{nameS}{" "}<span style={{ fontSize: "14px", fontWeight: "normal" }}>
                  ({artist})
                </span></marquee>

              </Text>
              {/* <Text userSelect='none'>{artist}</Text> */}
            </Box>
          </Box>
          <Stack mt={{base:4,md:8}} direction={"row"} spacing={4}>
            <Button
              w={"full"}
              bg={useColorModeValue("#151f21", "gray.900")}
              color={"white"}
              rounded={"md"}
              _hover={{
                transform: "translateY(-2px)",
                boxShadow: "lg",
              }}
            >
              Follow
            </Button>
          </Stack>
        </Box>
      </Box>
    </Center>
  );
}
