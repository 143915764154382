// theme.js

import { extendTheme } from '@chakra-ui/react';
import { BottomNavigationStyleConfig as BottomNavigation } from 'chakra-ui-bottom-navigation';

const customTheme = extendTheme({
  fonts: {
    heading: `'Lato','Roboto', sans-serif`,
    body: `'Lato','Roboto', sans-serif`,
  },
    colors: {
        primaryBg: '#161815',
        primaryText: '#a4a4a4',
        highlighted: '#6DE754',
        highlightedLight: '#94e187',
        card: '#7a7a7a',
    },
  components: {
    Input: {
      baseStyle: {
        field: {
          borderColor: 'highlighted',
          _hover: {
            borderColor: 'highlighted',
          },
          _focusVisible: {
            borderColor: 'highlighted',
            boxShadow: '0 0 0 1px blue.500',
          },
        },
      },
      sizes: {
        md: {
          field: {
            fontSize: '18px',
            padding: '22px 16px',
          },
        },
        sm:{
          field: {
            fontSize: '18px',
            padding: '18px 14px',
          },
        }
      },
      variants: {
        outline: {
          field: {
            borderColor: 'white',
          },
        },
        filled: {
          field: {
            bg: 'highlighted',
            _hover: {
              bg: 'gray.200',
            },
            _focus: {
              bg: 'white',
            },
          },
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'outline',
      },
    },
    BottomNavigation,
  },
});

export default customTheme;