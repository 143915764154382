import { Box, Flex, IconButton, Button, Stack, useDisclosure, Image, Avatar, Menu, MenuButton, MenuList, MenuGroup, MenuItem, MenuDivider, Center, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody, useColorMode } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import { Link, useNavigate } from 'react-router-dom'
import DesktopNav from './DesktopNav/DesktopNav';
import Promo from '../../assets/Promol.png'
import ButtonCuts from '../Button/ButtonCuts';
import BottomNavigationBar from '../BottomNavigation/BottomNavigation';
import { useModalVisibility } from '../../context/ModalToast/useModalToast';

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase/firebase";
import useLogout from '../../hooks/useLogout';
import { AiOutlineUser } from 'react-icons/ai';
import useAuthStore from '../../store/authStore';
import { BsChatSquareQuote, BsMoonStarsFill, BsSun, BsThreeDotsVertical } from 'react-icons/bs';
import { RiFileShredLine, RiLoginCircleLine, RiRestartLine, RiShutDownLine } from 'react-icons/ri';
import { useState } from 'react';
import { PageLayoutSpinner } from '../../Layout/PageLayout';
import HomeBottomNavigationBar from '../BottomNavigation/HomeBottomNavigation';
import ThemeChangerButton from '../Button/ThemeChanger/ThemeChangerButton';


const NAV_ITEMS = [
  //  {
  //     label: 'Pricing',
  //     link: '/pricing'
  //  },
  //  {
  //     label: 'About',
  //     link: '/demo'
  //  }
];


const Navbar = () => {

  const [authUser] = useAuthState(auth);
  const modal = useModalVisibility()
  const user = useAuthStore((state) => state.user)

  const redirect = useNavigate()

  const { handleLogout, isLoggingOut } = useLogout();
  const [isLocalLoading, setIsLocalLoading] = useState(false)

  const { colorMode, toggleColorMode } = useColorMode();



  const handleSignUpModal = () => {
    modal.toLoginOpen()
  }


  const handleLogoutHandle = () => {
    setIsLocalLoading(true);
    handleLogout()
    setTimeout(() => {
      setIsLocalLoading(false)
    }, 500)
  }

  if (isLocalLoading) {
    return (
      <PageLayoutSpinner text={'Loggin You Out ...'} />
    )
  }



  return (
    <Box
      pt={{ base: 0, sm: 0, md: 2, lg: 6 }}
      position="sticky"
      top={{ base: 0, sm: 0, md: -2, lg: -5 }}
      zIndex={1111111}
      backgroundColor="var(--primary-bg-color)"
    >
      <Flex
        border="none !important"
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4, sm: 4, md: 6, lg: 10 }}
        borderBottom={1}
        boxShadow={"sm"}
        borderStyle={"solid"}
        fontSize={"lg"}
        align={"center"}
      >
        <Flex
          flex={{ base: 1 }}
          justify={{ base: "start", md: "start" }}
          align={{ base: "center" }}
        >
          <Link to="/">
            <Image
              height={"34px"}
              width={"170px"}
              objectFit={"cover"}
              mr={2}
              src={Promo}
              alt="brand-logo"
              href="/"
            />
          </Link>

          <Flex
            display={{ base: "none", md: "flex" }}
            ml={{ base: 7, md: 7, lg: 10 }}
          >
            {authUser && <DesktopNav NAV_ITEMS={NAV_ITEMS} />}
          </Flex>
        </Flex>

        <Stack
          display={{ base: "none", md: "inline-flex" }}
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <ThemeChangerButton />
          {/* </Flex> */}
          {!authUser ? (
            <ButtonCuts
              text={"Sign In"}
              onClick={handleSignUpModal}
              variant={"outline"}
            />
          ) : null}
          {!authUser ? (
            <ButtonCuts text={"Get Started"} variant={"solid"} />
          ) : (
            <>
              <ButtonCuts text={"Dashboard"} onClick={() => redirect("/dashboard")} variant={"fill"} />
              <Popover placement="bottom" isLazy>
                <PopoverTrigger>
                  <Avatar
                    src={user?.profilePicURL}
                    cursor={'pointer'}
                  />
                </PopoverTrigger>
                <PopoverContent
                  w="fit-content"
                  _focus={{ boxShadow: "none" }}
                  bg="#bdbdbd"
                  border={"none"}
                >
                  <PopoverArrow bg={"#bdbdbd"} />
                  <PopoverBody>
                    <Stack>
                      <Button
                        w="194px"
                        variant="ghost"
                        onClick={handleLogoutHandle}
                        rightIcon={<RiShutDownLine />}
                        justifyContent="space-between"
                        fontWeight="normal"
                        // colorScheme={colorMode=='light'? "#000": 'blue'}
                        color={colorMode=='light'? "red": 'blue'}
                        fontSize="sm"
                      >
                        Log Out
                      </Button>
                    </Stack>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </>
          )}
        </Stack>

        <Flex
          flex={{ base: 1, md: "auto" }}
          justify={{ base: "right", md: "start" }}
          align={{ base: "center" }}
          display={{ base: "flex", md: "none" }}
        >
          {authUser ? (
            <Popover placement="bottom" closeOnBlur={true}>
              <PopoverTrigger>
                <Avatar
                  src={user?.profilePicURL}
                />
              </PopoverTrigger>
              <PopoverContent
                w="fit-content"
                _focus={{ boxShadow: "none" }}
                bg="#bdbdbd"
                border={"none"}
              >
                <PopoverArrow bg={"#bdbdbd"} />
                <PopoverBody>
                  <Stack>

                    <Button
                      w="194px"
                      variant="ghost"
                      onClick={handleLogoutHandle}
                      rightIcon={<RiShutDownLine />}
                      justifyContent="space-between"
                      fontWeight="normal"
                      color= "red"
                      fontSize="sm"
                    >
                      Log Out
                    </Button>
                    <Button
                      w="194px"
                      variant="ghost"
                      onClick={toggleColorMode}
                      rightIcon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                      justifyContent="space-between"
                      fontWeight="normal"
                      color={colorMode=='light'? "red": 'blue'}
                      fontSize="sm"
                    >
                      Toggle Theme
                    </Button>
                    {/* <ThemeChangerButton /> */}
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : (
            <Flex>
              <ThemeChangerButton />
              <IconButton
                aria-label={`Log In`}
                icon={<RiLoginCircleLine /> }
                onClick={handleSignUpModal}
                variant="ghost"
                size="lg"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              />
              
            </Flex>
          )}
        </Flex>
      </Flex>

      <Box display={{ base: "block", md: "none" }}>
        <HomeBottomNavigationBar />
      </Box>
    </Box>
  );
};

export default Navbar;