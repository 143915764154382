import { Box, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import Card from '../Card/Card'
import AOS from 'aos';
import 'aos/dist/aos.css'; 

import sammohan from "../../assets/sammohan.jpeg"
import js from "../../assets/JS.jpg";
import ankit from "../../assets/Ankit.jpg"
import khatra from "../../assets/Khatra.jpeg"
import alter from "../../assets/TPC Logo.png"
AOS.init({
    duration: 700,
})

let cards = [
    {name:"Sammohan",artist:"Rapper",age:"26",image:sammohan},
    {name:"JS Pahadi" , artist:"Producer",age:"23",image:js},
    {name:"Ankit" , artist:"Rapper",age:"24",image:ankit},
    {name:"Khatra" , artist:"Rapper",age:"24",image:khatra},
    // {name:"Alter" , artist:"Rapper",age:"24",image:alter},
]

const HeroSection = () => {

    const [artist , setHeroArtists] = useState(cards)



  return (
    <>
        <Box textAlign={"center"} margin={{base:"30px 0 0 50px",md:"auto"}}>
            <Text paddingTop={{base:'20px',md:"100px"}} fontSize={{base:"3xl",md:'5xl'}} fontWeight={500} color='var(--primary-text-color)'>Featured Artists</Text>
        </Box>
        <Box display={"flex"} justifyContent={"center"}  paddingTop={"20px"}>
            <Box flexDirection={{ base: 'column', sm: 'column', lg: 'row' }} gap={{base:3,md:14}} display={"flex"}  justifyContent={"center"}>
                {artist?.map((el,idx)=>{  
                    return (
                        <div key={idx} data-aos="flip-right">
                            <Card   nameS={el.name} age={el.age} image={el.image} artist={el.artist}/>
                        </div>
                        
                    )
                })}
            </Box>
        </Box>
    </>
  )
}

export default HeroSection
